{
  "title": "Between",
  "description": "I always thought that Bedfordshire was similar to the Far West.",
  "gallery": [
    {
      "caption": "The way to go",
      "location": "Leighton Buzzard, Bedfordshire",
      "image": "thewaytogo"
    },
    {
      "caption": "Bus Stop",
      "location": "Leighton Buzzard, Bedfordshire",
      "image": "busstop"
    },
    {
      "caption": "Chalk",
      "location": "Bedfordshire",
      "image": "chalk"
    },
    {
      "caption": "House",
      "location": "Leighton Buzzard, Bedfordshire",
      "image": "house"
    },
    {
      "caption": "Searching",
      "location": "Leighton Buzzard, Bedfordshire",
      "image": "searching"
    },
    {
      "caption": "Train tracks",
      "location": "Leighton Buzzard, Bedfordshire",
      "image": "traintracks"
    },
    {
      "caption": "Yellow door",
      "location": "Leighton Buzzard, Bedfordshire",
      "image": "yellowdoor"
    },
    {
      "caption": "Big and Small",
      "location": "Leighton Buzzard, Bedfordshire",
      "image": "bigsmall"
    }
  ]
}