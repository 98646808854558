import "./gallery"
import "./main.js"
import "../scss/main.scss"

window.galleries = {
    "between" : require('./galleries/between'),
    "dondevas" : require('./galleries/dondevas'),
    "zdrowie" : require('./galleries/zdrowie')
};




